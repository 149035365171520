<template>
  <div class="w-96 m-auto text-left">
    <div class="flex gap-3">
      <h1 class="text-lg">Hi, I'm Justyna</h1>
      <span class="animate-wiggle">👋</span>
    </div>
    <p>and I'm a software developer.</p>
    <p class="mt-2 text-sm">I'm not just passionate about writing code; I'm driven by the desire to create meaningful and impactful solutions that make a difference in the world. I'm excited to take on new challenges, learn new technologies, and contribute my expertise to innovative projects that drive progress and make a positive impact.</p>
    <p class="mt-2 text-sm">I also like travelling, books and horror movies.</p>
    <p class="mt-5 text-sm">Contact me: contact@justynasieja.pl</p>
  </div>

</template>

<script setup>

</script>