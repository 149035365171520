export const compass = [
    '6a2519d200ebca95532d832315500f2e1097bd1075aecf49567bd82b9b000d1c', //eng
    '26d2e77719002756c13cb1f3c73b26883c15b8242d7dc5f3d22603b9eb3b54eb', //eng
    '3bd81f800a544cf41a4fd95c5fbee9a04ae5adbbbc78002a7bdd7725b232f5bd', //pl
    '43faf8c739d21e03e5927db3855db73f45d6a8aea84741f0f2c3f2d1edb877d6', //pl special char
    'ab6eb063d98d8b45b1af81ba688717245c8645de5096b30bf7b539663629f75e', //pl
    '52152dd086ec45d34dd3cd048d2b7d66d1662a06d275293aff417e36136e72f8', //pl special char
    'b9425afde1f5e5eb96e85d5c8de35a813299cff343efba751cc9dfa349d486c5', //eng short
    '615d2f8ffe1c1923288f66f6a1c65f584d0a112bfc33d371c6ff374830664bac', //pl short
];

export const stamp = [
    '4f4a3854dd3e16e664eec1345ce4844ce6d7d54b558c70f00fcd02abbff7b43c', //no question mark
    'fe07185bb5b34f497a7a447e104790d3b9cf3dd178332ba5cc5195b78382da0a', //with qm
    '69e8713b2d833a1df2de72eb88dcfbf510f67ce7363576e24900a33f6d7bbcde', //opt2
    '0e71cbf9f0b49cc1f9fc38cb0a04199a61b1dda887e80da27933ea46e7523456', //opt2.
];