<template>
  <h3>404</h3> <p>Sorry, this page is missing 😣</p>
</template>

<script>

</script>

<style scoped>

</style>